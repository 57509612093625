import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from "./home.component";
import {AppRoutingModule} from "../../app-routing.module";
import {IconTextListModule} from "../../components/icon-text-list/icon-text-list.module";
import {FooterModule} from "../../components/footer/footer.module";
import {HeroSectionModule} from "../../components/hero-section/hero-section.module";
import {TopProductsModule} from "../../components/top-products/top-products.module";
import {EmailFormModule} from "../../components/email-form/email-form.module";
import {ProductCategoryListModule} from "../../components/product-category-list/product-category-list.module";
import {ImageModule} from "../../components/image/image.module";
import {BlogModule} from "../blog/components/blog.module";

@NgModule({
    declarations: [HomeComponent],
    imports: [
        CommonModule,
        AppRoutingModule,
        IconTextListModule,
        FooterModule,
        HeroSectionModule,
        TopProductsModule,
        EmailFormModule,
        ProductCategoryListModule,
        ImageModule,
        BlogModule
    ]
})
export class HomeModule {
}
