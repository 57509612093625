<div class="p-0 lg:p-16 pt-24 lg:pt-40">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            <div class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
                <div class="pt-3 lg:pt-8 px-10 relative">
                    @if (isEditMode) {
                        <app-image class="w-56 rounded-lg shadow-lg"
                                   [src]="'assets/images/' + product?.category?.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp'"
                                   alt="product pic"/>
                    }
                    <div>
                        <app-text [value]="'Име'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси име'"
                                [control]="nameControl"></app-text-input>

                    </div>
                    <div>
                        <app-text [value]="'Категорија'"></app-text>
                        <app-dropdown-menu
                                [control]="categoryControl"
                                [options]="categoryOptions"
                                (optionSelected)="onCategoryChange($event)"></app-dropdown-menu>
                    </div>
                    <div>
                        <app-text [value]="'Дизајн'"></app-text>
                        <app-text-input [placeholder]="'Внеси дизајн'"
                                        [control]="designIDControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Опис'"></app-text>
                        <app-text-input [placeholder]="'Внеси опис'"
                                        [control]="descriptionControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Продукти за реклама'"></app-text>
                        <app-text-input [placeholder]="'Внеси ид на продукти за реклама'"
                                        [control]="productAdIdsControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Содржина'"></app-text>
                        <app-rich-text [editorContent]="editorContent"
                                       [control]="contentControl"
                                       (editorDataChange)="onEditorDataChange($event)"></app-rich-text>
                    </div>
                    <div>
                        <app-text [value]="'Напомена'"></app-text>
                        <app-text-input [placeholder]="'Внеси напомена'"
                                        [control]="disclaimerControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Слаг'"></app-text>
                        <app-text-input [placeholder]="'Внеси слаг'"
                                        [control]="slugControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Клучни зборови'"></app-text>
                        <app-text-input [placeholder]="'Внеси клучни зборови'"
                                        [control]="keywordsControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                </div>
                <form [formGroup]="productControl" class="pt-3 lg:pt-8 px-10 relative">
                    <div>
                        <app-text [value]="'Подкатегорија'"></app-text>
                        <app-text-input [placeholder]="'Внеси подкатегорија'"
                                        [control]="subCategoryControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Слики'"></app-text>
                        <app-text-input [placeholder]="'Внеси слики'"
                                        [control]="imagesControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Достапни величини'"></app-text>
                        <app-text-input [placeholder]="'Внеси достапни величини'"
                                        [control]="availableSizesControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Достапни бои'"></app-text>
                        <app-text-input [placeholder]="'Внеси достапни бои'"
                                        [control]="availableColorsControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Цена'"></app-text>
                        <app-text-input [placeholder]="'Внеси цена'"
                                        [control]="priceControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    @if (isEditMode) {
                        <div>
                            <app-text [value]="'Оргинална цена'"></app-text>
                            <app-text-input class="pointer-events-none"
                                            [placeholder]="'Внеси оргинална цена'"
                                            [control]="originalPriceControl"
                                            [type]="'required'"></app-text-input>
                        </div>
                        <div>
                            <app-text [value]="'Датум на креирање'"></app-text>
                            <app-text-input [placeholder]="'Внеси датум на креирање'"
                                            [control]="createdControl"
                                            [type]="'required'"></app-text-input>

                        </div>
                        @if (product.discount) {
                            <div>
                                <app-text [value]="'Попуст'"></app-text>
                                <app-text-input class="pointer-events-none"
                                                [control]="discountControl"
                                                [type]="'required'"></app-text-input>
                            </div>
                            <app-secondary-button
                                    class="flex flex-shrink-0 flex-wrap bg-red-500"
                                    [value]="'Отстрани го попустот'"
                                    (click)="removeProductDiscount()"/>
                        } @else {
                            <div>
                                <app-text [value]="'Попуст'"></app-text>
                                <app-dropdown-menu
                                        [control]="discountControl"
                                        [options]="discountOptions"
                                        (optionSelected)="onDiscountChange($event)"></app-dropdown-menu>
                            </div>
                            <app-secondary-button
                                    class="flex flex-shrink-0 flex-wrap"
                                    [value]="'Аплицирај го попустот'"
                                    (click)="applyDiscount()"/>
                        }
                    }
                </form>
            </div>
            <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
                @if (isEditMode) {
                    <app-secondary-button
                            class="bg-red-600"
                            [value]="'Избриши го продуктот'"
                            (click)="openConfirmationModal()"/>
                    <app-confirmation-modal (confirmationEvent)="deleteProduct()"></app-confirmation-modal>

                    <app-primary-button [value]="'Промени ги податоците'"
                                        (click)="updateProduct()"></app-primary-button>
                } @else {
                    <app-primary-button [value]="'Додади нов продукт'"
                                        (click)="addProduct()"></app-primary-button>
                }

            </div>
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>