import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShopService} from "./service/shop.service";
import {Product} from "../../models/product";
import {Search} from "../../models/filters";
import {ActivatedRoute, Router} from "@angular/router";
import {Link} from "../../models/link";
import {animate, style, transition, trigger} from "@angular/animations";
import {Carousel} from "tw-elements";
import {Subscription} from "rxjs";
import {goToUrl, setCanonicalUrl} from "../../services/util/util";
import {Title} from "@angular/platform-browser";
import {environment} from "../../environments/environment.production";

@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.css'],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])]
})
export class ShopComponent implements OnInit, OnDestroy {

    pageTitle: string = ''

    activeRoute: string = '';

    products: Product[] = [];

    selectedProduct!: Product;

    searchValue: string = '';

    searchDto: Search = {
        search: {
            value: this.searchValue,
            caseInsensitive: true,
            like: true,
        },
    };

    pageNumber: number = 0;

    pageSize: number = 36;

    totalPages: number = 0;

    private productsPageSub!: Subscription;

    links: Link[] = [
        {text: "МАИЦИ", route: "shop/t_shirts"},
        {text: "ЗНАМИЊА", route: "shop/flags"},
        {text: "ШИШИЊА", route: "shop/bottles"},
        {text: "ДРЕСОВИ", route: "shop/jerseys"},
        {text: "ДУКСЕРИ", route: "shop/hoodies"},
        {text: "БЛУЗОНИ", route: "shop/sweat_shirts"},
        {text: "ОСТАНАТО", route: "shop/other",},
        {text: "ПОПУСТИ", route: "shop/on-discount",},
    ];

    constructor(
        private readonly shopService: ShopService,
        protected readonly router: Router,
        private route: ActivatedRoute,
        private titleService: Title
    ) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.pageTitle = data['title'] || 'Продавница';
            this.titleService.setTitle(this.pageTitle);
            const canonicalUrl = environment.domain.frontend + data['canonicalUrl'];
            setCanonicalUrl(canonicalUrl);
        });

        this.setSearchDto();
        this.getProductList();
    }

    ngOnDestroy(): void {
        if (this.productsPageSub) {
            this.productsPageSub.unsubscribe();
        }
    }

    setSelectedProduct(product: Product) {
        this.selectedProduct = product;
        const orderDialogCarousel = new Carousel(document.getElementById("order-dialog-carousel"));
        orderDialogCarousel.to(0);
    }

    getPaginatedProducts(): void {
        this.shopService.getAllProducts(this.pageNumber, this.pageSize);
        this.productsPageSub = this.shopService.productsPage$.subscribe((page) => {
            this.totalPages = page.totalPages;
            this.products = page.content;
        });
    }

    getProductList(): void {
        if (this.searchValue == '') {
            this.getPaginatedProducts();
        } else if (this.searchValue === 'on-discount') {
            this.getDiscountedProducts();
        } else {
            this.shopService.getProductsByCategory(this.searchValue).subscribe((products) => {
                this.products = products;
            })
        }
    }

    getDiscountedProducts(): void {
        this.shopService.getDiscountedProducts().subscribe(products => {
            this.products = products;
        });
    }

    setSearchDto(): void {
        this.setSearchValue();
        this.searchDto = {
            search: {
                value: this.searchValue,
                caseInsensitive: true,
                like: true,
            },
        };
    }

    setSearchValue() {
        this.route.url.subscribe(segments => {

            const shopIndex = segments.findIndex(segment => segment.path === 'shop');

            if (shopIndex !== -1 && shopIndex < segments.length - 1) {
                this.activeRoute = segments[shopIndex + 1].path;
                this.searchValue = this.activeRoute.endsWith('s') ? this.activeRoute.slice(0, -1) : this.activeRoute;

            } else {
                this.activeRoute = 'shop';
                this.searchValue = '';
            }
        });
    }

    onPageChange(page: number): void {
        this.pageNumber = page;
        this.getPaginatedProducts();
    }

    protected readonly goToUrl = goToUrl;
}