import {Component, HostListener, OnInit} from '@angular/core';
import {formatDate, goToUrl} from "../../../../services/util/util";
import {environment} from "../../../../environments/environment";
import {Blog} from "../../../../models/blog";
import {Router} from "@angular/router";
import {BlogService} from "../service/blog.service";
import {DatePipe} from "@angular/common";
import {AuthService} from "@auth0/auth0-angular";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-latest-blog-posts',
    templateUrl: './latest-blog-posts.component.html',
    styleUrl: './latest-blog-posts.component.css',
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('1.1s')),
        ])
    ]
})
export class LatestBlogPostsComponent implements OnInit {

    blogs: Blog[] = [];

    showAnimation: boolean = false;

    constructor(protected readonly router: Router,
                private blogService: BlogService,
                protected datePipe: DatePipe,
                public auth: AuthService
    ) {
    }

    ngOnInit(): void {
        this.blogService.getAllBlogs().subscribe((blogs) => this.blogs = blogs.slice(0, 3));
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentElement = document.getElementById('latestBlogPosts');
        if (componentElement) {
            const rect = componentElement.getBoundingClientRect();
            this.showAnimation = rect.top <= window.innerHeight && rect.bottom >= 0;
        }
    }

    protected readonly goToUrl = goToUrl;
    protected readonly environment = environment;
    protected readonly formatDate = formatDate;
}
