<ng-container class="w-40">
    @switch (layout) {
        @case ('vertical') {
            <div data-te-ripple-init
                 data-te-ripple-color="light">
                <button (click)="goToUrl(router, 'shop/product/' + (product?.slug ? product?.slug + '-' + product?.id : product?.id))">
                    <div class="flex justify-center items-center">
                        <app-image [class]="'max-w-md w-full transform hover:scale-105 transition-transform duration-200'"
                                   [src]="'assets/images/' + product?.category?.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp'"></app-image>
                    </div>
                    <app-text class="font-medium text-center"
                              [value]="setProductCategory(product?.category) + product?.name"></app-text>
                    <div class="flex justify-center">
                        <app-text
                                [ngClass]="(product?.discount)? 'text-red-600': ''"
                                class="text-center" [value]="product?.price?.toString() + ' ден.'"></app-text>
                        @if (product?.discount) {
                            <app-text class="text-center line-through px-0 sm:px-1.5"
                                      [value]="product?.originalPrice?.toString() + ' ден.'"></app-text>
                            <app-text class="text-center text-red-600"
                                      [value]="'| ' + product?.discount?.discountPercentage?.toPrecision(2) + '% &darr;'"></app-text>
                        }

                    </div>
                </button>
                @if (showAddToCartButton) {
                    <app-primary-button [value]="'Додади во кошничка'"
                                        data-te-toggle="modal"
                                        data-te-target="#orderDialog"
                                        class="flex mx-auto"></app-primary-button>
                }
                @if (auth.user$ | async; as user) {
                    @if (user.email === environment.admin) {
                        <app-secondary-button [value]="'Уреди'"
                                              class="pt-2 flex mx-auto"
                                              (click)="goToUrl(router, 'shop/product/' + product?.id + '/edit')"
                        ></app-secondary-button>
                    }
                }
            </div>
        }
        @case ('horizontal') {
            <div>
                <div class="lg:flex justify-between">
                    <div class="flex">
                        @if (orderItem) {
                            <app-image [class]="'w-32 lg:w-44'"
                                       [src]="setOrderItemImage(orderItem.color)"></app-image>
                        }
                        <div class="text-left pl-5 lg:my-auto lg:text-base">
                            <app-text class="lg:text-2xl font-medium text-left"
                                      [value]="setProductCategory(orderItem?.product?.category) + orderItem?.product?.name"></app-text>
                            <app-text [value]="orderItem?.product?.price + ' ден.'"></app-text>
                            <app-text [value]="orderItem?.size"></app-text>
                        </div>
                    </div>
                    <div class="flex grid-cols-2 gap-7">
                        <div class="grid my-auto">
                            <app-quantity-selector
                                    (quantityChange)="onQuantityChange($event)"
                                    [quantity]="quantity"
                                    [control]="quantityControl"></app-quantity-selector>
                            <button class="text-sm lg:text-base text-left lg:text-center underline px-2"
                                    (click)="emitRemoveItemEvent()">Oтстрани
                            </button>
                        </div>
                        @if (orderItem && orderItem.product && orderItem.quantity) {
                            <app-text class="hidden lg:flex lg:text-right my-auto text-2xl"
                                      [value]="orderItemPrice + ' ден.'"></app-text>
                        }
                    </div>
                </div>
            </div>
        }
    }
</ng-container>
