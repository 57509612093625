import {Component, OnInit} from '@angular/core';
import {Observable, switchMap} from "rxjs";
import {Blog} from "../../../../models/blog";
import {ActivatedRoute} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DatePipe} from "@angular/common";
import {BlogService} from "../service/blog.service";
import {environment} from "../../../../environments/environment.production";
import {formatDate, setCanonicalUrl} from "../../../../services/util/util";
import {ShopService} from "../../../shop/service/shop.service";
import {Product} from "../../../../models/product";
import {Link} from "../../../../models/link";
import {FBQ} from "../../../../models/fbq";

declare let fbq: FBQ;

@Component({
    selector: 'app-blog-detail',
    templateUrl: './blog-detail.component.html',
    styleUrl: './blog-detail.component.css'
})
export class BlogDetailComponent implements OnInit {

    blog!: Observable<Blog>;

    products: Product[] = [];

    links: Link[] = [
        {
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            text: "Блог",
            route: environment.navigation.blog,
        }]

    constructor(private route: ActivatedRoute,
                private blogService: BlogService,
                private meta: Meta,
                private titleService: Title,
                protected datePipe: DatePipe,
                protected shopService: ShopService
    ) {
    }

    ngOnInit(): void {
        this.blog = this.route.paramMap.pipe(
            switchMap(params => {
                const slugAndId = params.get('slugAndId')!;

                let id: number;

                if (slugAndId.includes('-')) {
                    id = +slugAndId.split('-').pop()!;
                } else {
                    id = +slugAndId;
                }
                return this.blogService.getBlogById(id);
            })
        );

        this.blog.subscribe(blog => {
            // this.updateLinks(product.category);
            const canonicalUrl = environment.domain.frontend + environment.navigation.blog + '/blog/' + (blog?.slug ? blog?.slug + '-' + blog?.id : blog?.id);
            setCanonicalUrl(canonicalUrl);
            this.setTitle(blog);
            this.setMetaDescription(blog);
            // this.setStructuredData(product);
            this.setMetaKeywords(blog);
            this.shopService.getProductsByIds(blog.productAdIds).subscribe((products) => this.products = products)
            fbq('track', 'ViewContent', {
                content_name: blog?.title,
                content_ids: [`BLOG_${blog.id}`],
                content_category: blog?.category,
                content_type: 'article',
            });
        });

    }

    setTitle(blog: Blog) {
        this.titleService.setTitle(blog.title);
    }

    setMetaDescription(blog: Blog) {
        const metaDescription = (blog.summary.length > 160) ? blog.summary.substring(0, 157) + '...' : blog.summary;

        this.meta.updateTag({name: 'description', content: metaDescription});
    }

    private setMetaKeywords(blog: Blog): void {
        const keywords = blog.keywords?.join(', ') ?? '';

        this.meta.updateTag({name: 'keywords', content: keywords});
    }

    protected readonly formatDate = formatDate;
}