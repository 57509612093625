import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductManageComponent} from "./product-manage.component";
import {TextInputModule} from "../../../../components/text-input/text-input.module";
import {TextModule} from "../../../../components/text/text.module";
import {ReactiveFormsModule} from "@angular/forms";
import {PrimaryButtonComponent} from "../../../../components/button/primary/primary.button.component";
import {SecondaryButtonComponent} from "../../../../components/button/secondary/secondary.button.component";
import {ConfirmationModalModule} from "../../../../components/confirmation-modal/confirmation-modal.module";
import {DropdownMenuModule} from "../../../../components/dropdown/dropdown-menu.module";
import {ImageModule} from "../../../../components/image/image.module";
import {RichTextComponent} from "../../../../components/rich-text/rich-text.component";


@NgModule({
    declarations: [ProductManageComponent],
    exports: [
        ProductManageComponent
    ],
    imports: [
        CommonModule,
        TextInputModule,
        TextModule,
        ReactiveFormsModule,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        ConfirmationModalModule,
        DropdownMenuModule,
        ImageModule,
        RichTextComponent
    ],
})
export class ProductManageModule {
}